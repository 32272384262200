import { useQuery } from '@apollo/react-hooks';

import { GetUserStateQuery } from '../graphql/GetUserStateQuery.graphql';
import type { GetUserState } from '../graphql/__types__/GetUserStateQuery';

export const useUserState = () => {
	const {
		data: userStateData,
		loading: isLoading,
		error: userStateDataError,
	} = useQuery<GetUserState>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		GetUserStateQuery,
	);

	const hasJira = userStateData?.confluence_tenantContext?.licensedProducts.some(
		(product) =>
			product.productKey.toLowerCase().includes('jira-software') &&
			product.licenseStatus === 'ACTIVE',
	);

	const isSiteAdmin = userStateData?.isSiteAdmin;

	return {
		hasJira,
		isSiteAdmin,
		isLoading,
		userStateDataError,
	};
};
